<template>
	<EditorMenuBubble
		v-slot="{ isActive, getNodeAttrs, commands }"
		:editor="editor"
	>
		<!--
		New component is used and v-slot props are passed to it so we could access
		them inside template. Helps to avoid a lot of repetition.
		-->
		<EditTextTypeControls
			:is-active="isActive"
			:get-node-attrs="getNodeAttrs"
			:commands="commands"
		/>
	</EditorMenuBubble>
</template>

<script>
import { EditorMenuBubble } from 'tiptap';

import { useTextEditor } from '@/use/text-editor/useTextEditor';

import EditTextTypeControls from './EditTextTypeControls.vue';

export default {
	components: {
		EditTextTypeControls,
		EditorMenuBubble,
	},
	setup() {
		const { editor } = useTextEditor();

		return { editor };
	},
};
</script>
