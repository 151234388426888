import { render, staticRenderFns } from "./EditTextAi.vue?vue&type=template&id=c00231da&scoped=true&"
import script from "./EditTextAi.vue?vue&type=script&lang=js&"
export * from "./EditTextAi.vue?vue&type=script&lang=js&"
import style0 from "./EditTextAi.vue?vue&type=style&index=0&id=c00231da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c00231da",
  null
  
)

export default component.exports