import { render, staticRenderFns } from "./EditTextTypeControls.vue?vue&type=template&id=bbccf1c2&scoped=true&"
import script from "./EditTextTypeControls.vue?vue&type=script&lang=js&"
export * from "./EditTextTypeControls.vue?vue&type=script&lang=js&"
import style0 from "./EditTextTypeControls.vue?vue&type=style&index=0&id=bbccf1c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbccf1c2",
  null
  
)

export default component.exports