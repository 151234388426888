<template>
	<EditorMenuBubble
		v-slot="{ commands }"
		:editor="editor"
	>
		<div class="toolbar__group">
			<EditTextButton
				:disabled="!commands.undoDepth()"
				@click="commands.undo"
			>
				<ZyroSvg name="arrow-back" />
			</EditTextButton>
			<EditTextButton
				:disabled="!commands.redoDepth()"
				@click="commands.redo"
			>
				<ZyroSvg name="arrow-forward" />
			</EditTextButton>
		</div>
	</EditorMenuBubble>
</template>

<script>
import { EditorMenuBubble } from 'tiptap';

import { useTextEditor } from '@/use/text-editor/useTextEditor';

import EditTextButton from './EditTextButton.vue';

export default {
	components: {
		EditTextButton,
		EditorMenuBubble,
	},
	setup() {
		const { editor } = useTextEditor();

		return { editor };
	},
};
</script>
