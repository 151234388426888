<template>
	<ZyroPopup
		type="no-padding"
		:show-close-button="false"
		:offset="selectPosition"
		@toggle="handleSelectToggle"
	>
		<template #trigger>
			<EditTextButton
				class="toggle"
				:is-square="false"
				is-rounded-left
			>
				<span>{{ TEXT_TYPES[currentClass].title }}</span>
				<span class="toggle__text-size">{{ getFontSize(currentClass) }}</span>
				<ZyroSvg name="chevron-down-small" />
			</EditTextButton>
		</template>
		<template #default="{ toggle }">
			<div
				ref="text-type-select"
				class="select"
			>
				<ZyroButton
					v-for="({ title, typographyName }, className) in TEXT_TYPES"
					:key="title"
					class="select__item z-body-small"
					:class="{ 'select__item--active': className === currentClass }"
					:icon-left="className === currentClass ? 'check-mark' : ''"
					icon-dimensions="16px"
					@click="setTextType(className, toggle)"
				>
					<span
						class="select__item--title"
						:class="{
							'heading': HEADING_TEXT_TYPES[className],
							'paragraph': PARAGRAPH_TEXT_TYPES[className],
						}"
						:style="{ fontSize: `${typographyPreviewSizes[typographyName]}px` }"
					>
						{{ title }}
					</span>
					<span class="select__item--size">{{ getFontSize(className) }}</span>
				</ZyroButton>
			</div>
			<ZyroSeparator />
			<ZyroButton
				icon-left="type"
				class="toggle-drawer"
				@click="openDrawer"
			>
				{{ $t('builder.editText.editTextStyles') }}
			</ZyroButton>
		</template>
	</ZyroPopup>
</template>

<script>
import { mapState } from 'vuex';

import {
	TYPOGRAPHY_STYLES_LIST_TAB_ID,
	TYPOGRAPHY_TAB_ID,
} from '@/components/builder-drawers/drawers/constants';
import { useDrawerTabs } from '@/components/builder-drawers/drawers/use/useDrawerTabs';
import {
	PROPERTY_FONT_SIZE,
	PROPERTY_M_FONT_SIZE,
} from '@/constants/globalStyles';
import {
	USER_STYLES_HEADING_SELECT_KEY,
	USER_STYLES_PARAGRAPH_SELECT_KEY,
} from '@/store/builder/constants/drawerPages';
import {
	USER_STYLES_DRAWER,
	GLOBAL_STYLES_DRAWER,
} from '@/store/builder/constants/drawers';
import {
	CHANGE_DRAWER_PAGE,
	mapActionsGui,
	TOGGLE_DRAWER,
} from '@/store/builder/gui';
import {
	TEXT_TYPES,
	HEADING_NODE_NAME,
	PARAGRAPH_NODE_NAME,
	PARAGRAPH_TEXT_TYPES,
	HEADING_TEXT_TYPES,
} from '@/use/text-editor/constants';
import { useTextTypePreviewCalculation } from '@/use/useTextTypePreviewCalculation';
import { isDevelopment } from '@/utils/flags';
import { isWithinScreen } from '@/utils/positioning';

import EditTextButton from './EditTextButton.vue';

const POSITION_BOTTOM = {
	top: 0,
	left: 0,
};
const POSITION_TOP = {
	bottom: 0,
	left: 0,
};

export default {
	components: { EditTextButton },
	props: {
		isActive: {
			type: Object,
			required: true,
		},
		getNodeAttrs: {
			type: Function,
			required: true,
		},
		commands: {
			type: Object,
			required: true,
		},
	},
	setup(props, context) {
		const { changeCurrentTab } = useDrawerTabs();
		const { typographyPreviewSizes } = useTextTypePreviewCalculation(props, context);

		return {
			changeCurrentTab,
			typographyPreviewSizes,
			TEXT_TYPES,
			HEADING_TEXT_TYPES,
			PARAGRAPH_TEXT_TYPES,
			HEADING_NODE_NAME,
			PARAGRAPH_NODE_NAME,
		};
	},
	data() {
		return { selectPosition: POSITION_BOTTOM };
	},
	computed: {
		...mapState(['website']),
		...mapState('user', ['isUserDesigner']),
		...mapState('gui', ['isMobileView']),
		/**
		 * TipTap isActive is a bit buggy at the V1. A lot of issues should be fixed in V2.
		 * For now, we evaluate active node and current class manually.
		 */
		currentClass: ({ getNodeAttrs }) => getNodeAttrs(HEADING_NODE_NAME).className
			|| getNodeAttrs(PARAGRAPH_NODE_NAME).className,
		currentClassTypographyName: ({ currentClass }) => TEXT_TYPES[currentClass].typographyName,
		currentTypographyPageKey: ({ currentClass }) => (PARAGRAPH_TEXT_TYPES[currentClass]
			? USER_STYLES_PARAGRAPH_SELECT_KEY
			: USER_STYLES_HEADING_SELECT_KEY),
		activeNodeName: ({
			getNodeAttrs,
			currentClass,
		}) => {
			if (getNodeAttrs(HEADING_NODE_NAME).className === currentClass) {
				return HEADING_NODE_NAME;
			}

			if (getNodeAttrs(PARAGRAPH_NODE_NAME).className === currentClass) {
				return PARAGRAPH_NODE_NAME;
			}

			return null;
		},
	},
	methods: {
		...mapActionsGui({
			toggleDrawer: TOGGLE_DRAWER,
			changeDrawerPage: CHANGE_DRAWER_PAGE,
		}),
		getFontSize(className) {
			const currentTypographyName = TEXT_TYPES[className].typographyName;
			const currentTypography = this.website.styles[currentTypographyName];
			const desktopFontSize = currentTypography[PROPERTY_FONT_SIZE];
			const mobileFontSize = currentTypography[PROPERTY_M_FONT_SIZE];

			return this.isMobileView ? (mobileFontSize || desktopFontSize) : desktopFontSize;
		},
		setTextType(className, toggle) {
			if (className === this.currentClass) {
				return;
			}

			if (this.activeNodeName === HEADING_NODE_NAME) {
				this.commands.heading({
					tag: this.getNodeAttrs(HEADING_NODE_NAME).tag,
					style: this.getNodeAttrs(HEADING_NODE_NAME).style,
					className,
				});
			}

			if (this.activeNodeName === PARAGRAPH_NODE_NAME) {
				this.commands.paragraph({
					style: this.getNodeAttrs(PARAGRAPH_NODE_NAME).style,
					className,
				});
			}

			toggle();
		},
		async handleSelectToggle(isOpen) {
			if (!isOpen) {
				this.selectPosition = POSITION_BOTTOM;

				return;
			}

			await this.$nextTick();
			if (!isWithinScreen(this.$refs['text-type-select'])) {
				this.selectPosition = POSITION_TOP;
			}
		},
		openDrawer() {
			if (this.isZyroUser || isDevelopment || this.isUserDesigner) {
				this.toggleDrawer(GLOBAL_STYLES_DRAWER);
				this.changeCurrentTab({
					drawer: GLOBAL_STYLES_DRAWER,
					tabId: TYPOGRAPHY_TAB_ID,
				});
			} else {
				this.toggleDrawer(USER_STYLES_DRAWER);
				this.changeCurrentTab({
					drawer: USER_STYLES_DRAWER,
					tabId: TYPOGRAPHY_STYLES_LIST_TAB_ID,
				});
				this.changeDrawerPage({
					drawerKey: USER_STYLES_DRAWER,
					pageKey: this.currentTypographyPageKey,
					options: { currentTypographyType: this.currentClassTypographyName },
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.select {
	display: flex;
	flex-direction: column;
	width: 260px;
	border-radius: $border-radius-small;

	&__item {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 8px 16px 8px 32px;
		text-align: start;

		&--active {
			padding-left: 8px;
		}

		&--size {
			color: $grey-600;
		}

		&::v-deep {
			.zyro-button__text {
				justify-content: space-between;
				width: 100%;
			}
		}

		&:hover {
			color: $dark;
			background-color: $grey-200;
		}
	}
}

.heading {
	font-family: var(--font-primary);
}

.paragraph {
	font-family: var(--font-secondary);
}

.toggle {
	display: flex;
	justify-content: space-between;
	width: 203px;

	&__text-size {
		margin: 0 8px 0 auto;
		color: $grey-600;
	}
}

.toggle-drawer {
	justify-content: center;
	width: 100%;
	padding: 18px 18px 20px 20px;
}

@include mobile-view {
	.toggle {
		width: 150px;
		padding-right: 0;
	}
}
</style>
