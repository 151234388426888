<template>
	<ZyroPopup
		:offset="{
			top:38,
			left:'unset'
		}"
		type="no-padding"
		:show-close-button="false"
	>
		<template slot="trigger">
			<EditTextButton>
				<ZyroSvg :name="iconName" />
			</EditTextButton>
		</template>
		<ZyroIconControls
			class="text-align__controls"
			:current.sync="align"
			:icons="$options.valuesAlign"
			theme="rounded"
			:toggleable="false"
		/>
	</ZyroPopup>
</template>

<script>
import {
	mapGetters,
	mapMutations,
} from 'vuex';

import EditTextButton from './EditTextButton.vue';

const valuesAlign = [
	{
		value: 'left',
		icon: 'align-left',
	},
	{
		value: 'center',
		icon: 'align-center',
	},
	{
		value: 'right',
		icon: 'align-right',
	},
	{
		value: 'justify',
		icon: 'align-justify',
	},
];

export default {
	valuesAlign,
	components: { EditTextButton },
	computed: {
		...mapGetters(['currentElement']),
		align: {
			get() {
				return this.currentElement.settings.styles.text || 'left';
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { text: newValue } } } });
			},
		},
		iconName() {
			return valuesAlign.find((item) => item.value === this.align).icon;
		},
	},
	methods: mapMutations(['setElementData']),
};
</script>

<style lang="scss" scoped>
.text-align {
	&__controls {
		margin: 0;
	}
}
</style>
