import { computed } from '@vue/composition-api';

import {
	PROPERTY_FONT_SIZE,
	TYPOGRAPHY_HEADINGS,
	TYPOGRAPHY_PARAGRAPHS,
} from '@/constants/globalStyles';
import {
	filterObject,
	mapObject,
} from '@/utils/object';

const MAX_HEADING_PREVIEW_SIZE = 32;
const MIN_HEADING_PREVIEW_SIZE = 14;
const MAX_PARAGRAPH_PREVIEW_SIZE = 18;
const MIN_PARAGRAPH_PREVIEW_SIZE = 14;

export const previewFormula = ({
	previewMinValue,
	previewMaxValue,
	currentMaxValue,
	currentMinValue,
}, sizeToPreview) => Math.round((previewMinValue + (
	((sizeToPreview - currentMinValue) * (previewMaxValue - previewMinValue))
	/ (currentMaxValue - currentMinValue)
)) * 10) / 10;

export const useTextTypePreviewCalculation = (props, context) => {
	const { $store } = context.root;

	const headingFontSizes = computed(() => mapObject(
		filterObject($store.state.website.styles, ({ key }) => TYPOGRAPHY_HEADINGS.includes(key)),
		({ value }) => Number.parseInt(value[PROPERTY_FONT_SIZE], 10),
	));

	const paragraphFontSizes = computed(() => mapObject(
		filterObject($store.state.website.styles, ({ key }) => TYPOGRAPHY_PARAGRAPHS.includes(key)),
		({ value }) => Number.parseInt(value[PROPERTY_FONT_SIZE], 10),
	));

	const allTypographyFontSizes = computed(
		() => ({
			...headingFontSizes.value,
			...paragraphFontSizes.value,
		}),
	);

	const headingFontSizeArray = computed(() => Object.values(headingFontSizes.value));

	const paragraphFontSizeArray = computed(() => Object.values(paragraphFontSizes.value));

	const maxHeadingFontSize = computed(() => Math.max(...headingFontSizeArray.value));

	const minHeadingFontSize = computed(() => Math.min(...headingFontSizeArray.value));

	const maxParagraphFontSize = computed(() => Math.max(...paragraphFontSizeArray.value));

	const minParagraphFontSize = computed(() => Math.min(...paragraphFontSizeArray.value));

	const typographyPreviewSizes = computed(
		() => mapObject(allTypographyFontSizes.value, ({
			key: typographyType,
			value: typographyFontSize,
		}) => {
			const previewFormulaValues = TYPOGRAPHY_HEADINGS.includes(typographyType) ? {
				previewMinValue: MIN_HEADING_PREVIEW_SIZE,
				previewMaxValue: MAX_HEADING_PREVIEW_SIZE,
				currentMaxValue: maxHeadingFontSize.value,
				currentMinValue: minHeadingFontSize.value,
			} : {
				previewMinValue: MIN_PARAGRAPH_PREVIEW_SIZE,
				previewMaxValue: MAX_PARAGRAPH_PREVIEW_SIZE,
				currentMaxValue: maxParagraphFontSize.value,
				currentMinValue: minParagraphFontSize.value,
			};

			return previewFormula({ ...previewFormulaValues }, typographyFontSize);
		}),
	);

	return {
		typographyPreviewSizes,
		headingFontSizes,
		paragraphFontSizes,
		headingFontSizeArray,
		paragraphFontSizeArray,
		minParagraphFontSize,
		minHeadingFontSize,
		maxParagraphFontSize,
		maxHeadingFontSize,
	};
};
