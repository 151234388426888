<template>
	<ZyroPopup
		type="no-padding"
		:show-close-button="false"
		class="toolbar__group"
		:offset="{
			top: 0,
			left: -144
		}"
	>
		<template #trigger>
			<EditTextButton is-rounded-right>
				<ZyroSvg name="dots" />
			</EditTextButton>
		</template>
		<EditorMenuBubble
			v-slot="{ commands, getMarkAttrs }"
			:editor="editor"
		>
			<div class="options">
				<h5 class="options__title z-body-small">
					{{ $t('builder.editText.editTextMore.title') }}
				</h5>
				<button
					v-for="letterCase in LETTERCASES"
					:key="letterCase"
					class="options__item z-body-small"
					@click="setLetterCase(commands.letterCase, getMarkAttrs, letterCase)"
				>
					{{ $t(`builder.editText.editTextMore.letterCases.${letterCase}`) }}
					<!--
						https://github.com/ueberdosis/tiptap/issues/877
						`isActive` should be used instead of `getMarksAttrs`, but `isActive`
						works buggy on marks. Should be fixed in tiptap2
						-->
					<ZyroSvg
						v-if="isMarkActive(getMarkAttrs, letterCase)"
						name="check-mark"
					/>
				</button>
			</div>
		</EditorMenuBubble>
	</ZyroPopup>
</template>

<script>
import { EditorMenuBubble } from 'tiptap';

import {
	LETTERCASES,
	LETTERCASE_MARK_NAME,
} from '@/use/text-editor/constants';
import { useTextEditor } from '@/use/text-editor/useTextEditor';

import EditTextButton from './EditTextButton.vue';

export default {
	components: {
		EditTextButton,
		EditorMenuBubble,
	},
	setup() {
		const { editor } = useTextEditor();

		return {
			LETTERCASES,
			editor,
		};
	},
	methods: {
		isMarkActive(getMarkAttributes, letterCase) {
			return getMarkAttributes(LETTERCASE_MARK_NAME).letterCase === letterCase;
		},
		setLetterCase(letterCaseCommand, getMarkAttributes, letterCase) {
			letterCaseCommand(this.isMarkActive(getMarkAttributes, letterCase) ? {} : { letterCase });
		},
	},
};
</script>

<style lang="scss" scoped>
.options {
	display: flex;
	flex-direction: column;
	width: 180px;
	padding: 8px 0;

	&__title {
		width: 100%;
		padding: 10px 0 10px 18px;
		color: $grey-600;
		text-align: start;
		text-transform: uppercase;
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 10px 12px 10px 18px;

		&:hover {
			cursor: pointer;
			background-color: $grey-200;
		}
	}
}
</style>
