<template>
	<ZyroPopup
		:offset="{
			top:38,
			left:'unset'
		}"
		type="no-padding"
		:show-close-button="false"
	>
		<template slot="trigger">
			<EditTextButton>
				<ZyroSvg :name="iconName" />
			</EditTextButton>
		</template>
		<ZyroIconControls
			class="text-justify__controls"
			:current.sync="justify"
			:icons="$options.valuesJustify"
			theme="rounded"
			:toggleable="false"
		/>
	</ZyroPopup>
</template>

<script>
import {
	mapGetters,
	mapMutations,
} from 'vuex';

import EditTextButton from './EditTextButton.vue';

const valuesJustify = [
	{
		value: 'flex-start',
		icon: 'align-top',
	},
	{
		value: 'center',
		icon: 'align-middle',
	},
	{
		value: 'flex-end',
		icon: 'align-bottom',
	},
];

export default {
	valuesJustify,
	components: { EditTextButton },
	computed: {
		...mapGetters(['currentElement']),
		justify: {
			get() {
				return this.currentElement.settings.styles.justify || 'flex-start';
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { justify: newValue } } } });
			},
		},
		iconName() {
			return valuesJustify.find((item) => item.value === this.justify).icon;
		},
	},
	methods: mapMutations(['setElementData']),
};
</script>

<style lang="scss" scoped>
.text-justify {
	&__controls {
		margin: 0;
	}
}
</style>
