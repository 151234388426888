<template>
	<EditTextButton
		class="toolbar__group drawer-trigger"
		data-qa="buildertextelement-editmode-iconwriter"
		@click="toggleDrawer(AI_WRITER_DRAWER)"
	>
		<ZyroSvg
			class="ai-writer-icon"
			name="app-ai-text-generator"
		/>
	</EditTextButton>
</template>

<script>
import { AI_WRITER_DRAWER } from '@/store/builder/constants/drawers';
import {
	mapActionsGui,
	TOGGLE_DRAWER,
} from '@/store/builder/gui';

import EditTextButton from './EditTextButton.vue';

export default {
	components: { EditTextButton },
	setup() {
		return { AI_WRITER_DRAWER };
	},
	methods: mapActionsGui({ toggleDrawer: TOGGLE_DRAWER }),
};
</script>

<style lang="scss" scoped>
.ai-writer-icon {
	width: 22px;
	height: 22px;
}
</style>
