<template>
	<EditorMenuBubble
		v-slot="{ commands, isActive, getMarkAttrs }"
		:editor="editor"
	>
		<ZyroPopup
			ref="popup"
			type="editor"
			:offset="popupOffset"
			:title="$t('common.linkSettings')"
			:show-footer="true"
			class="toolbar__group"
			@save="saveLink(commands.link)"
		>
			<template #trigger>
				<EditTextButton
					:is-active="isActive.link()"
					@click="getLink(getMarkAttrs)"
				>
					<ZyroSvg name="link" />
				</EditTextButton>
			</template>
			<template #default="{ toggle }">
				<div>
					<LinkSettings
						ref="linksettings"
						no-content
						:base-target="linkSettingsData.target || '_self'"
						:base-href="linkSettingsData.href || ''"
						@hook:mounted="getPopupOffset"
						@settings-update="linkSettingsData = {
							...linkSettingsData,
							...$event
						}"
					/>
					<ZyroButton
						v-if="isActive.link()"
						theme="plain"
						class="remove-link"
						@click.stop="removeLink(commands.link, toggle)"
					>
						{{ $t('common.removeLink') }}
					</ZyroButton>
				</div>
			</template>
		</ZyroPopup>
	</EditorMenuBubble>
</template>

<script>
import { EditorMenuBubble } from 'tiptap';
import { mapState } from 'vuex';

import LinkSettings from '@/components/builder-controls/edit-button/LinkSettings.vue';
import { LINK_MARK_NAME } from '@/use/text-editor/constants';
import { useTextEditor } from '@/use/text-editor/useTextEditor';
import { isWithinScreen } from '@/utils/positioning';

import EditTextButton from './EditTextButton.vue';

const POPUP_POSITION_BOTTOM = {
	bottom: 55,
	right: 0,
};
const POPUP_POSITION_TOP = {
	top: 55,
	right: 0,
};

export default {
	components: {
		EditTextButton,
		LinkSettings,
		EditorMenuBubble,
	},
	setup() {
		const { editor } = useTextEditor();

		return { editor };
	},
	data() {
		return {
			popupOffset: POPUP_POSITION_TOP,
			linkSettingsData: {
				href: '',
				isUrlValid: true,
				target: '',
			},
		};
	},
	computed: mapState(['website']),
	methods: {
		saveLink(linkCommand) {
			const {
				href,
				target,
			} = this.linkSettingsData;
			const internalLink = this.website.pages[href]?.path;

			if (internalLink) {
				linkCommand({
					href: internalLink,
					target,
					internalLink: href,
				});
			} else {
				linkCommand({
					href,
					target,
				});
			}
		},
		getLink(getMarkAttributes) {
			const {
				internalLink,
				href,
				target,
			} = getMarkAttributes(LINK_MARK_NAME);

			this.linkSettingsData.href = internalLink || href;
			this.linkSettingsData.target = target;
		},
		removeLink(linkCommand, toggle) {
			linkCommand({ href: null });
			toggle();
		},
		getPopupOffset() {
			const isPopupWithinScreen = isWithinScreen(this.$refs.linksettings.$el);

			this.popupOffset = isPopupWithinScreen ? POPUP_POSITION_TOP : POPUP_POSITION_BOTTOM;
		},
	},
};
</script>

<style lang="scss" scoped>
.remove-link {
	margin-top: 16px;
	color: $secondary;
}
</style>
