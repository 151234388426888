<template>
	<EditorMenuBubble
		v-slot="{ commands, getMarkAttrs }"
		:editor="editor"
		class="toolbar__group"
	>
		<EditTextButton>
			<ZyroColorPicker
				:value="getMarkAttrs(COLOR_MARK_NAME).color || 'var(--colors-light)'"
				@input="commands.color({ color: $event })"
			/>
		</EditTextButton>
	</EditorMenuBubble>
</template>

<script>
import { EditorMenuBubble } from 'tiptap';

import { COLOR_MARK_NAME } from '@/use/text-editor/constants';
import { useTextEditor } from '@/use/text-editor/useTextEditor';

import EditTextButton from './EditTextButton.vue';

export default {
	components: {
		EditTextButton,
		EditorMenuBubble,
	},
	setup() {
		const { editor } = useTextEditor();

		return {
			editor,
			COLOR_MARK_NAME,
		};
	},
};
</script>
