var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ZyroPopup',{attrs:{"type":"no-padding","show-close-button":false,"offset":_vm.selectPosition},on:{"toggle":_vm.handleSelectToggle},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('EditTextButton',{staticClass:"toggle",attrs:{"is-square":false,"is-rounded-left":""}},[_c('span',[_vm._v(_vm._s(_vm.TEXT_TYPES[_vm.currentClass].title))]),_c('span',{staticClass:"toggle__text-size"},[_vm._v(_vm._s(_vm.getFontSize(_vm.currentClass)))]),_c('ZyroSvg',{attrs:{"name":"chevron-down-small"}})],1)]},proxy:true},{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('div',{ref:"text-type-select",staticClass:"select"},_vm._l((_vm.TEXT_TYPES),function(ref,className){
var title = ref.title;
var typographyName = ref.typographyName;
return _c('ZyroButton',{key:title,staticClass:"select__item z-body-small",class:{ 'select__item--active': className === _vm.currentClass },attrs:{"icon-left":className === _vm.currentClass ? 'check-mark' : '',"icon-dimensions":"16px"},on:{"click":function($event){return _vm.setTextType(className, toggle)}}},[_c('span',{staticClass:"select__item--title",class:{
						'heading': _vm.HEADING_TEXT_TYPES[className],
						'paragraph': _vm.PARAGRAPH_TEXT_TYPES[className],
					},style:({ fontSize: ((_vm.typographyPreviewSizes[typographyName]) + "px") })},[_vm._v(" "+_vm._s(title)+" ")]),_c('span',{staticClass:"select__item--size"},[_vm._v(_vm._s(_vm.getFontSize(className)))])])}),1),_c('ZyroSeparator'),_c('ZyroButton',{staticClass:"toggle-drawer",attrs:{"icon-left":"type"},on:{"click":_vm.openDrawer}},[_vm._v(" "+_vm._s(_vm.$t('builder.editText.editTextStyles'))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }