<template>
	<EditorMenuBubble
		v-slot="{ commands, isActive }"
		:editor="editor"
		class="toolbar__group"
	>
		<div>
			<EditTextButton
				:is-active="isActive.bold()"
				@click="commands.bold"
			>
				<ZyroSvg name="bold" />
			</EditTextButton>
			<EditTextButton
				:is-active="isActive.italic()"
				@click="commands.italic"
			>
				<ZyroSvg name="italic" />
			</EditTextButton>
			<EditTextButton
				:is-active="isActive.underline()"
				@click="commands.underline"
			>
				<ZyroSvg name="underline" />
			</EditTextButton>
		</div>
	</EditorMenuBubble>
</template>

<script>
import { EditorMenuBubble } from 'tiptap';

import { useTextEditor } from '@/use/text-editor/useTextEditor';

import EditTextButton from './EditTextButton.vue';

export default {
	components: {
		EditTextButton,
		EditorMenuBubble,
	},
	setup() {
		const { editor } = useTextEditor();

		return { editor };
	},
};
</script>
