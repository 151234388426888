var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditorMenuBubble',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
var getMarkAttrs = ref.getMarkAttrs;
return [_c('ZyroPopup',{ref:"popup",staticClass:"toolbar__group",attrs:{"type":"editor","offset":_vm.popupOffset,"title":_vm.$t('common.linkSettings'),"show-footer":true},on:{"save":function($event){return _vm.saveLink(commands.link)}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('EditTextButton',{attrs:{"is-active":isActive.link()},on:{"click":function($event){return _vm.getLink(getMarkAttrs)}}},[_c('ZyroSvg',{attrs:{"name":"link"}})],1)]},proxy:true},{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('div',[_c('LinkSettings',{ref:"linksettings",attrs:{"no-content":"","base-target":_vm.linkSettingsData.target || '_self',"base-href":_vm.linkSettingsData.href || ''},on:{"hook:mounted":_vm.getPopupOffset,"settings-update":function($event){_vm.linkSettingsData = Object.assign({}, _vm.linkSettingsData,
						$event)}}}),(isActive.link())?_c('ZyroButton',{staticClass:"remove-link",attrs:{"theme":"plain"},on:{"click":function($event){$event.stopPropagation();return _vm.removeLink(commands.link, toggle)}}},[_vm._v(" "+_vm._s(_vm.$t('common.removeLink'))+" ")]):_vm._e()],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }