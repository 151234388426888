<template>
	<BaseEditControls
		:block-id="blockId"
		:block-ref="blockRef"
		:button-title="$t('builder.editText.title')"
	>
		<BuilderToolbar>
			<EditTextType />
			<EditTextColor />
			<EditTextFontStyle class="display-none-mobile-down" />
			<EditTextAlign class="display-none-mobile-down" />
			<EditTextJustify class="display-none-mobile-down" />
			<EditTextLink />
			<EditTextHistory />
			<EditTextAi />
			<EditTextMore />
		</BuilderToolbar>
	</BaseEditControls>
</template>

<script>
import BaseEditControls from '@/components/builder-controls/BaseEditControls.vue';
import BuilderToolbar from '@/components/builder-view/components/BuilderToolbar.vue';

import EditTextAi from './EditTextAi.vue';
import EditTextAlign from './EditTextAlign.vue';
import EditTextColor from './EditTextColor.vue';
import EditTextFontStyle from './EditTextFontStyle.vue';
import EditTextHistory from './EditTextHistory.vue';
import EditTextJustify from './EditTextJustify.vue';
import EditTextLink from './EditTextLink.vue';
import EditTextMore from './EditTextMore.vue';
import EditTextType from './EditTextType.vue';

export default {
	components: {
		BaseEditControls,
		BuilderToolbar,
		EditTextFontStyle,
		EditTextColor,
		EditTextAlign,
		EditTextJustify,
		EditTextType,
		EditTextLink,
		EditTextAi,
		EditTextMore,
		EditTextHistory,
	},
	props: {
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
	},
};
</script>
