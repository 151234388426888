import { render, staticRenderFns } from "./EditTextAlign.vue?vue&type=template&id=226961e5&scoped=true&"
import script from "./EditTextAlign.vue?vue&type=script&lang=js&"
export * from "./EditTextAlign.vue?vue&type=script&lang=js&"
import style0 from "./EditTextAlign.vue?vue&type=style&index=0&id=226961e5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226961e5",
  null
  
)

export default component.exports