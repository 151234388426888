<template>
	<button
		class="toolbar-button z-body-small"
		:class="{
			'toolbar-button--active': isActive,
			'toolbar-button--square': isSquare,
			'toolbar-button--rounded-left': isRoundedLeft,
			'toolbar-button--rounded-right': isRoundedRight
		}"
		v-on="$listeners"
	>
		<slot />
	</button>
</template>

<script>
export default {
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		isSquare: {
			type: Boolean,
			default: true,
		},
		isRoundedLeft: {
			type: Boolean,
			default: false,
		},
		isRoundedRight: {
			type: Boolean,
			default: false,
		},
		showDropdownIndicator: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.toolbar-button {
	$height: 36px;

	display: flex;
	align-items: center;
	justify-content: center;
	height: $height;
	padding: 0 16px;
	transition: all 0.2s ease;

	&--active {
		background-color: $grey-200;
	}

	&--square {
		width: $height;
		padding: 0;
	}

	&--rounded-left {
		border-top-left-radius: $rounded-toolbar-radius;
		border-bottom-left-radius: $rounded-toolbar-radius;
	}

	&--rounded-right {
		border-top-right-radius: $rounded-toolbar-radius;
		border-bottom-right-radius: $rounded-toolbar-radius;
	}

	&:disabled {
		color: $grey-300;
	}

	&:hover {
		cursor: pointer;
		background-color: $grey-200;
	}
}
</style>
